








































































import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { ApiValidationErrorResponse } from '@/types/api/Response';
import Vue from 'vue';
import mixinsVue from './mixins.vue';
export default Vue.extend({
  'name': 'ModalAddNewAdmin',
  'mixins': [mixinsVue],
  'props': {
    'value': {
      'type': Object,
      'required': true
    }
  },
  data () {
    return {
      'isAdd': true
    };
  },
  'methods': {
    onSubmit () {
      this.$emit('submit');
    },
    update () {
      this.isAdd = false;
      (this.$refs.form as any).reset();
      (this as any).show();
    },
    create () {
      this.isAdd = true;
      (this.$refs.form as any).reset();
      (this as any).show();
    },
    setErrors (errors: ApiValidationErrorResponse) {
      (this.$refs.form as VeeValidateObserverRef).setErrors(errors);
    }
  }
});
