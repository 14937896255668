
















































import { initPagination, Pagination, updatePagination } from '@/types/Pagination';
import { initTableSortAndFilter, updateTableSort, TableSortAndFilter } from '@/types/TableSortAndFilter';
import {
  DealerTypeListRequest,
  DealerTypeListResponse,
  DealerTypeListDetailResponse,
  DealerTypeResponse
} from '@/types/api/DealerTypeList';
import SortableTable from '@/components/SortableTable.vue';
import ModalAddNewDealerType from '@/components/modals/AddNewDealerType.vue';
import ModalConfirmation from '@/components/modals/Confirmation.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import { cleanupFilterRequest, createFilterRequest } from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
import { AxiosError } from 'axios';
import { ApiResponse } from '@/types/api/Response';
const deleteDealerTypeConfirmationTitle = 'Delete Confirmation';
const deleteDealerTypeConfirmationMessage = '<p>Are you sure you want to delete this dealer type?</p><p>This action cannot be undone!</p>';
export default Vue.extend({
  'name': 'DealerTypeList',
  'components': {
    SortableTable,
    ModalAddNewDealerType,
    ModalConfirmation
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  data () {
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': 'dealerType.name',
          'display': 'Dealer Type',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'count',
          'display': 'No. Of Dealers',
          'width': 220,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'dealerType.createdAt',
          'display': 'Created Date',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'dealerType.createdBy.name',
          'display': 'Created By',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 150,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-nowrap',
          'hasDisplay': false,
          'buttons': [
            {
              'cssClass': 'btn-iris-table btn-iris-edit',
              'event': 'edit'
            },
            {
              'cssClass': 'btn-iris-table btn-iris-delete',
              'event': 'delete'
            }
          ]
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as Array<DealerTypeListDetailResponse>,
      'form': {
        'name': '',
        'dealerTypeId': 0,
        'isSubmitting': false
      },
      'debounceGetList': null as unknown,
      'deleteDealerType': {
        'confirmationTitle': deleteDealerTypeConfirmationTitle,
        'confirmationMessage': deleteDealerTypeConfirmationMessage,
        'showConfirmButton': true,
        'discardButtonText': 'Discard',
        'dealerTypeId': -1
      }
    };
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      this.axios
        .get('dealer-type/list', {
          'params': getRequest
        })
        .then((res) => {
          const data = res.data as DealerTypeListResponse;
          this.tableData = data.result.items;

          const paginationUpdate = data.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    addDealerType () {
      this.form.dealerTypeId = 0;
      this.form.name = '';

      (this.$refs.modalAddNewDealerType as any).create();
    },
    editDealerType (rowIndex: number) {
      this.form.dealerTypeId = this.tableData[rowIndex].id
        ? this.tableData[rowIndex].id
        : 1;

      this.axios.get(`dealer-type/${this.form.dealerTypeId}`)
        .then(({ data }) => {
          const response = data as DealerTypeResponse;

          this.form.name = response.result.name;

          (this.$refs.modalAddNewDealerType as any).update();
        });
    },
    deleteDealerTypeFn (rowIndex: number) {
      this.deleteDealerType.dealerTypeId = this.tableData[rowIndex]
        ? this.tableData[rowIndex].id
        : 0;

      this.deleteDealerType.confirmationTitle = deleteDealerTypeConfirmationTitle;
      this.deleteDealerType.confirmationMessage = deleteDealerTypeConfirmationMessage;
      this.deleteDealerType.showConfirmButton = true;
      this.deleteDealerType.discardButtonText = 'Discard';
      (this.$refs.deleteDealerTypeConfirmation as any).show();
    },
    confirmDeleteDealerType () {
      (this.$refs.deleteDealerTypeConfirmation as any).hide(() => {
        this.axios.delete(`dealer-type/${this.deleteDealerType.dealerTypeId}`).then(({ data }) => {
          this.getList();

          this.deleteDealerType.confirmationTitle = 'Success';
          this.deleteDealerType.confirmationMessage = data.message;
          this.deleteDealerType.showConfirmButton = false;
          this.deleteDealerType.discardButtonText = 'Dismiss';
        }).catch((err:AxiosError) => {
          this.deleteDealerType.confirmationTitle = 'Error';
          if (err && err.response) {
            const data = err.response.data as ApiResponse;
            this.deleteDealerType.confirmationMessage = data.message;
          }
          this.deleteDealerType.showConfirmButton = false;
          this.deleteDealerType.discardButtonText = 'Dismiss';
        }).finally(() => {
          (this.$refs.deleteDealerTypeConfirmation as any).show();
        });
      });
    },
    discardDeleteDealerType () {
      (this.$refs.deleteDealerTypeConfirmation as any).hide();
    },
    submitForm () {
      if (this.form.dealerTypeId === 0) {
        this.form.isSubmitting = true;
        this.axios
          .post('dealer-type', {
            'name': this.form.name
          })
          .then(() => {
            (this.$refs.modalAddNewDealerType as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status === 422 &&
              err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddNewDealerType as any).setErrors(result);
              }
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      } else {
        this.form.isSubmitting = true;
        this.axios
          .put('dealer-type', {
            'dealerTypeId': this.form.dealerTypeId,
            'name': this.form.name
          })
          .then(() => {
            (this.$refs.modalAddNewDealerType as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status === 422 &&
              err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddNewDealerType as any).setErrors(result);
              }
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      }
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    }
  }
});
