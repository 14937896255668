var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"modalContent",staticClass:"modal fade modal-irispro",attrs:{"tabindex":"-1","role":"dialog","data-backdrop":"static","aria-hidden":"true","data-keyboard":"false"}},[_c('div',{staticClass:"modal-dialog modal-dialog",staticStyle:{"max-width":"720px"},attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title"}),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.hide}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.isAdd ? "Add New Dealer Type" : "View Dealer Type")+" ")]),_c('div',{staticClass:"modal-form"},[_c('div',{staticStyle:{"width":"350px","margin":"0 auto"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"fm",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"mb-3"},[_c('ValidationProvider',{attrs:{"vid":"name","name":"type of dealer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value.name),expression:"value.name"}],class:{
                        'form-control': true,
                        'is-invalid': errors.length > 0,
                      },attrs:{"type":"text","placeholder":"Type of Dealer"},domProps:{"value":(_vm.value.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.value, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{ref:"hiddenSubmit",staticClass:"invisible",attrs:{"type":"submit"}})])]}}])})],1)])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"modal-button d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-iris",attrs:{"disabled":_vm.value.isSubmitting},on:{"click":function($event){return _vm.$refs.hiddenSubmit.click()}}},[_vm._v(" "+_vm._s(_vm.isAdd ? "Create Dealer Type" : "Update Dealer Type")+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }